import { Tag } from '@ui-library/core'
import React from 'react'
import {
  BoostStatus,
  Programme,
} from '../../../../utils/myAdfactoryApi/swaggerApi'

export const BoostStatusText = ({
  program,
}: {
  program: Programme
}): React.ReactElement => {
  let description = ''
  let subDescription = ''
  let pelletColor: '#49CFAD' | '#FFA557' | '#CE0030' | null = null // green | orange | red | null
  let isDeleted = false

  switch (program.status) {
    case BoostStatus.Boost_Recurrent:
      description = 'Boosté pour le mois en cours'
      subDescription = 'et sera reconduit le mois prochain'
      pelletColor = '#49CFAD'
      break
    case BoostStatus.Boost_NonRecurrent:
      description = 'Boosté pour le mois en cours'
      subDescription = 'et ne sera pas reconduit le mois prochain'
      pelletColor = '#49CFAD'
      break
    case BoostStatus.Boost_Deleted:
      description = 'Boosté pour le mois en cours'
      subDescription = 'et ne sera pas reconduit le mois prochain'
      pelletColor = '#49CFAD'
      isDeleted = true
      break
    case BoostStatus.Pending_NewClient:
    case BoostStatus.Boost_InProgess_Signifi:
      description = 'Sera boosté très prochainement'
      pelletColor = '#FFA557'
      break
    case BoostStatus.Pending_ExistingClient:
      description = 'Sera boosté le mois prochain'
      subDescription = 'et modifiable jusqu’à la fin du mois'
      pelletColor = '#FFA557'
      break
    case BoostStatus.Pending_Deleted:
      description = 'Ne sera pas boosté'
      pelletColor = '#CE0030'
      isDeleted = true
      break
    default:
      break
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {pelletColor !== null && (
          <span
            data-testid="pellet"
            style={{
              background: pelletColor,
              width: '12px',
              height: '12px',
              borderRadius: '6px',
              margin: '5px',
            }}
          />
        )}
        <p style={{ fontWeight: '600' }}>{description !== '' && description}</p>
      </div>
      {subDescription !== '' && (
        <p style={{ marginTop: '0.5em' }}>{subDescription}</p>
      )}

      {isDeleted && (
        <span style={{ marginTop: '0.75em' }}>
          <Tag label="Supprimé" kind="inverse" className="customTag" />
        </span>
      )}
    </div>
  )
}
