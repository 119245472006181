import { Tag } from '@ui-library/core/lib/molecules'
import { NewBuildAgency } from '../../../../utils/myAdfactoryApi/swaggerApi'

type HeaderTitleProps = {
  boostSocialImmoData: NewBuildAgency
  selectedProgramsCount: number
  downSaleDate?: string
  boostCount: number
}

export const BoostSocialImmoHeaderTitle = ({
  boostSocialImmoData,
  selectedProgramsCount,
  downSaleDate,
  boostCount,
}: HeaderTitleProps) => (
  <div className="listsHeaderTitle">
    {boostSocialImmoData.nbMaxBoost && boostSocialImmoData.nbMaxBoost <= 1 ? (
      <h2>Quel programme voulez-vous Booster ?</h2>
    ) : (
      <h2>Quels programmes voulez-vous Booster ?</h2>
    )}
    <br />
    <span>{`${boostSocialImmoData.clientId} - ${boostSocialImmoData.clientName} - ${boostSocialImmoData.contractId} - `}</span>
    {boostCount > 1 ? (
      <span>
        <b>{boostCount}</b> boosts en cours et
      </span>
    ) : (
      <span>
        <b>{boostCount}</b> boost en cours et
      </span>
    )}
    <span>
      <b>{` ${selectedProgramsCount}/${boostSocialImmoData.nbMaxBoost} `}</b>
    </span>
    {boostSocialImmoData.nbMaxBoost && boostSocialImmoData.nbMaxBoost > 1 ? (
      <span>programmes sélectionnés</span>
    ) : (
      <span>programme sélectionné</span>
    )}
    {downSaleDate && (
      <span style={{ marginLeft: '.75em' }}>
        <Tag label="Downsell" kind="default" />
      </span>
    )}
  </div>
)
