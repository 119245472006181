import { CardPhoto, Checkbox, Toggle } from '@ui-library/core'
import { Icon } from '@ui-library/core/lib/atoms'
import { ExternalLinkRegular } from '@ui-library/icons'
import React from 'react'
import {
  BoostStatus,
  NewBuildAgency,
  Programme,
} from '../../../../utils/myAdfactoryApi/swaggerApi'
import { BoostStatusText } from '../BoostStatusText/BoostStatusText'

type BoostSocialImmoTableBodyProps = {
  programs: Programme[]
  setPrograms: React.Dispatch<React.SetStateAction<Programme[]>>
  setIsVisible: React.Dispatch<React.SetStateAction<boolean[]>>
  isVisible: boolean[]
  mainImgArray: (string | undefined)[]
  boostSocialImmoData: NewBuildAgency
  setSelectedProgramsCount: React.Dispatch<React.SetStateAction<number>>
  setShouldDisplayNotification: React.Dispatch<React.SetStateAction<boolean>>
  downSaleDate?: string
}

export const BoostSocialImmoTableBody = ({
  programs,
  setPrograms,
  setIsVisible,
  isVisible,
  mainImgArray,
  boostSocialImmoData,
  setSelectedProgramsCount,
  setShouldDisplayNotification,
  downSaleDate,
}: BoostSocialImmoTableBodyProps) => {
  return programs.map((program: Programme) => (
    <tr className="count" key={program.idProgramme}>
      <td aria-label="programId">
        <div className="tdWrapper centerContent">
          <div className="cellText">{program.idProgramme}</div>
          <span className="bigDivider" />
        </div>
      </td>
      <td aria-label="projet">
        <div className="tdWrapper centerContent">
          <div className="cellText">{program.projet}</div>
          <span className="bigDivider" />
        </div>
      </td>
      <td aria-label="localité">
        <div className="tdWrapper centerContent">
          <div className="cellText">
            {program.city} ({program.codePostal})
          </div>
          <span className="bigDivider" />
        </div>
      </td>
      <td aria-label="urlAnnonce">
        <div className="tdWrapper centerContent">
          <div className="cellText">
            <a
              href={program.url}
              aria-label="External Link"
              target="_blank"
              rel="noreferrer"
            >
              <div
                className="externalLinkContainer"
                role="none"
                onMouseEnter={() => {
                  const newArray = [...isVisible]
                  newArray[programs.indexOf(program)] = true
                  setIsVisible(newArray)
                }}
                onMouseLeave={() => {
                  const newArray = [...isVisible]
                  newArray[programs.indexOf(program)] = false
                  setIsVisible(newArray)
                }}
              >
                <Icon icon={ExternalLinkRegular} />
              </div>

              {isVisible[programs.indexOf(program)] && (
                <div className="imageContainer">
                  <CardPhoto
                    src={mainImgArray[programs.indexOf(program)] ?? ''}
                    alt="Annonce"
                    hasIntersected
                  />
                </div>
              )}
            </a>
          </div>
          <span className="bigDivider" />
        </div>
      </td>
      <td aria-label="boostStatus">
        <div className="tdWrapper centerContent compensateHeight">
          <div className="cellText composedCell">
            <BoostStatusText program={program} />

            <div style={{ paddingRight: '3em' }}>
              <Toggle
                size="medium"
                checked={program.isChecked || false}
                onChange={() => {
                  onToggleChange(
                    boostSocialImmoData,
                    programs,
                    program,
                    setPrograms,
                    setSelectedProgramsCount,
                    setShouldDisplayNotification,
                    downSaleDate
                  )
                }}
              />
            </div>
          </div>
          {downSaleDate && <span className="bigDivider" />}
        </div>
      </td>
      {downSaleDate && (
        <td aria-label="toKeep">
          <div className="tdWrapper centerContent compensateHeight">
            <div className="cellText">
              {program.isChecked && (
                <Checkbox
                  checked={program.toKeep ?? false}
                  size="medium"
                  variant="normal"
                  label=""
                  onChange={onCheckboxChange(program)}
                  disabled={
                    program.status === BoostStatus.Pending_ExistingClient
                  }
                />
              )}
            </div>
          </div>
        </td>
      )}
    </tr>
  ))

  function onCheckboxChange(program: Programme): (value: boolean) => void {
    return () => {
      const updatedProgram = { ...program }
      updatedProgram.toKeep = !program.toKeep
      setPrograms(
        programs.map((p) =>
          p.idProgramme === program.idProgramme ? updatedProgram : p
        )
      )
    }
  }
}

const onToggleChange = (
  boostSocialImmoData: NewBuildAgency,
  programs: Programme[],
  program: Programme,
  setPrograms: React.Dispatch<React.SetStateAction<Programme[]>>,
  setSelectedProgramsCount: React.Dispatch<React.SetStateAction<number>>,
  setShouldDisplayNotification: React.Dispatch<React.SetStateAction<boolean>>,
  downSaleDate?: string
) => {
  const isMaxBoostChecked = Boolean(
    boostSocialImmoData?.nbMaxBoost &&
      programs.filter((p) => p.isChecked).length >
        boostSocialImmoData.nbMaxBoost
  )

  const isMaxBoost = Boolean(
    boostSocialImmoData?.nbMaxBoost &&
      programs?.filter(
        (p) =>
          p.status === BoostStatus.Boost_Recurrent ||
          p.status === BoostStatus.Boost_NonRecurrent ||
          p.status === BoostStatus.Boost_Deleted ||
          p.status === BoostStatus.Boost_InProgess_Signifi
      ).length >= boostSocialImmoData.nbMaxBoost
  )

  // Only perform the toggle change if the Toggle is already checked (to always allow to uncheck the Toggle) or
  // the number of selected programs is less than or equal to the maximum allowed and sratus is not in progress signifi
  if (
    (program.isChecked || !isMaxBoostChecked) &&
    program.status !== BoostStatus.Boost_InProgess_Signifi
  ) {
    let updatedProgram = {
      ...program,
      // Toggle state can be changed only if the program is not deleted
      isChecked: computeIsToggleChecked(program),
    }

    if (downSaleDate) {
      updatedProgram.toKeep = updatedProgram.isChecked
    }

    updatedProgram.status = computeNextStatus(
      programs,
      updatedProgram,
      isMaxBoostChecked,
      isMaxBoost
    )

    let mutatedPrograms = programs.map((p) => {
      if (p.idProgramme === program.idProgramme) {
        return updatedProgram
      }
      return p
    })

    setPrograms(mutatedPrograms)
    setSelectedProgramsCount(mutatedPrograms.filter((p) => p.isChecked).length)

    // If after checking the Toggle, the number of selected programs exceeds the maximum allowed, uncheck the Toggle and display a notification
    // This will be performed on only 1 Toggle (program) that exceeds the maximum allowed, the other actions will be ignored
    if (
      boostSocialImmoData.nbMaxBoost &&
      mutatedPrograms.filter((p) => p.isChecked).length >
        boostSocialImmoData.nbMaxBoost
    ) {
      setShouldDisplayNotification(true)
      setTimeout(() => {
        updatedProgram = {
          ...program,
          isChecked: false,
        }

        mutatedPrograms = mutatedPrograms.map((p) => {
          if (p.idProgramme === program.idProgramme) {
            return updatedProgram
          }
          return p
        })

        setPrograms(mutatedPrograms)
        setSelectedProgramsCount(
          mutatedPrograms.filter((p) => p.isChecked).length
        )
        setShouldDisplayNotification(false)
      }, 1000)
    }
  }
}

const computeIsToggleChecked = (program: Programme) =>
  program.status !== BoostStatus.Boost_Deleted &&
  program.status !== BoostStatus.Pending_Deleted &&
  !program.isChecked

export const computeNextStatus = (
  allPrograms: Programme[],
  updatedProgram: Programme,
  isMaxBoostChecked: boolean,
  isMaxBoost: boolean
): BoostStatus => {
  let nextStatus: BoostStatus = BoostStatus.Default

  switch (updatedProgram.status) {
    case BoostStatus.Default:
      if (updatedProgram.isChecked) {
        if (
          isMaxBoost ||
          (isMaxBoostChecked &&
            allPrograms.find(
              (p) =>
                p.status === BoostStatus.Boost_Recurrent ||
                p.status === BoostStatus.Boost_NonRecurrent ||
                p.status === BoostStatus.Boost_Deleted
            ))
        ) {
          nextStatus = BoostStatus.Pending_ExistingClient
        } else {
          // If we reach this point, it means that there is no other program already boosted
          // So the program can be Boosted 'immediately'
          nextStatus = BoostStatus.Pending_NewClient
        }
      }
      break
    case BoostStatus.Boost_Recurrent:
      if (!updatedProgram.isChecked) {
        nextStatus = BoostStatus.Boost_NonRecurrent
      }
      break

    case BoostStatus.Boost_NonRecurrent:
      if (updatedProgram.isChecked) {
        nextStatus = BoostStatus.Boost_Recurrent
      }
      break

    case BoostStatus.Boost_Deleted:
      nextStatus = BoostStatus.Boost_Deleted
      break

    case BoostStatus.Pending_NewClient:
    case BoostStatus.Pending_ExistingClient:
      if (!updatedProgram.isChecked) {
        nextStatus = BoostStatus.Default
      }
      break

    case BoostStatus.Pending_Deleted:
      nextStatus = BoostStatus.Pending_Deleted
      break
    case BoostStatus.Boost_InProgess_Signifi:
      nextStatus = BoostStatus.Boost_InProgess_Signifi
      break
    default:
      break
  }

  return nextStatus
}
