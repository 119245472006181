import React, { useEffect, useState } from 'react'

import latinize from 'latinize'
import Highlighter from 'react-highlight-words'

import { AddItem, ClientName, ItemListStyled, ItemValue } from '../../styled'

import type {
  ClientBo,
  NewBuildAgency,
} from '../../../../utils/myAdfactoryApi/swaggerApi'
import { capitalize } from '../../../../utils/tsUtils'

type ItemListProps = {
  value: ClientBo | NewBuildAgency
  inputValue: string
  myList: ClientBo[] | NewBuildAgency[]
  addElemToMyList: (value: ClientBo | NewBuildAgency) => void
}

const ItemList = ({
  value,
  inputValue,
  myList,
  addElemToMyList,
}: ItemListProps): React.ReactElement => {
  const [alreadyInMyList, setAlreadyInMyList] = useState(false)
  useEffect(() => {
    if (
      myList.some(
        (elem: ClientBo | NewBuildAgency) => elem.clientId === value.clientId
      )
    ) {
      setAlreadyInMyList(true)
    } else {
      setAlreadyInMyList(false)
    }
  }, [myList, value])

  const clientNameFormatted = capitalize(value.clientName?.toLowerCase())

  return (
    <ItemListStyled
      $isDisabled={alreadyInMyList}
      data-testid="locationResult"
      className={alreadyInMyList ? 'isDisabled' : ''}
      onClick={() => {
        !alreadyInMyList && addElemToMyList(value)
      }}
    >
      <ItemValue data-testid="valueLocation">
        <p>
          {!alreadyInMyList ? (
            <>
              <Highlighter
                searchWords={[inputValue, inputValue.replace(/ /g, '-')]}
                autoEscape
                sanitize={latinize}
                textToHighlight={value.clientId}
              />
              {value.clientName && (
                <ClientName>
                  <Highlighter
                    searchWords={[inputValue, inputValue.replace(/ /g, '-')]}
                    autoEscape
                    sanitize={latinize}
                    textToHighlight={clientNameFormatted}
                  />
                </ClientName>
              )}
              {'contractId' in value && ` ${value.contractId}`}
              {'devisNum' in value && ` ${value.devisNum}`}
            </>
          ) : (
            <>
              {value.clientId}
              <ClientName>{clientNameFormatted}</ClientName>
              {'contractId' in value && ` ${value.contractId}`}
              {'devisNum' in value && ` ${value.devisNum}`}
            </>
          )}
        </p>
      </ItemValue>
      <AddItem>
        <p>Sélectionner</p>
      </AddItem>
    </ItemListStyled>
  )
}

export default ItemList
